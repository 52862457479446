import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';

import {
  BasicField,
  DropdownFieldNew,
  SwitchField,
  ReportField,
} from 'components/fields';
import { BasicButton } from 'components/buttons';
import { inputPatterns } from 'helpers';
import {
  STEP_TYPES,
  TRIGGER_TYPE_OPTIONS,
  ACTION_FAILURE_OPTIONS,
  FIRST_TRIGGER_TYPE_OPTIONS,
} from 'const/scenario';
import cancelIcon from 'assets/icons/cross-grey.svg';
import saveIcon from 'assets/icons/save.svg';
import trashIcon from 'assets/icons/trash.svg';

import StepTypeField from './StepTypeField';
import './style.scss';

const EditNodeActionForm = ({
  onCancel,
  onSubmit,
  initialValues,
  isPendingGetValidators,
  lastUpdatedGetValidators,
  validators,
  onDelete,
  flowId,
  readOnly = false,
  reportFields,
  isFirstNode,
}) => {
  const { t } = useTranslation();

  const stylesDropdownField = {
    label: { flex: 3 },
    content: { flex: 7 },
    container: { marginBottom: 12 },
  };
  const stylesSwitchField = {
    label: { flex: 3, marginRight: 12 },
    wrapper: { marginBottom: 12 },
  };
  const stylesBasicField = {
    container: { marginBottom: 12 },
    label: { flex: 3 },
    inputContainer: { flex: 7 },
  };
  // const optionsJumpType = [
  //   {
  //     label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.UNCONDITIONAL'),
  //     value: 0,
  //   },
  //   {
  //     label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.USER_REPLY'),
  //     value: 1,
  //   },
  //   {
  //     label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.CONDITION'),
  //     value: 2,
  //   },
  // ];
  // const optionsReplyType = [
  //   {
  //     label: 'Regexp',
  //     value: 1,
  //   },
  //   // {
  //   //   label: 'Keyphrase',
  //   //   value: 2,
  //   // },
  //   // {
  //   //   label: 'Intent',
  //   //   value: 3,
  //   // },
  // ];

  return (
    <Form
      onSubmit={({ selectedFlowId, selectedNodeId, ...values }, formApi) => onSubmit({
        ...values,
        flowId: selectedFlowId,
        nodeId: selectedNodeId,
      }, formApi)}
      initialValues={{
        validatorId: 1,
        ...initialValues,
      }}
      mutators={arrayMutators}
      render={
        ({
          handleSubmit,
          values,
          form,
          submitting,
          invalid,
        }) => (
          <form onSubmit={handleSubmit} className="edit-node-action-form">
            <h1 className="edit-node-action-form__title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.STEP_EDIT')}
            </h1>
            <h2 className="edit-node-action-form__sub-title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.GENERAL')}
            </h2>
            <Field
              name="label"
              type="border"
              label={t('NEW_TRANSLATES.SCENARIOS_CONSTRUCTOR.INSTANCES.CHATBOTS_STEP_EDIT_LABEL')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <Field
              name="triggerType"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.TRIGGER_TYPE')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={isFirstNode ? FIRST_TRIGGER_TYPE_OPTIONS : TRIGGER_TYPE_OPTIONS}
              readOnly={readOnly || isFirstNode}
            />
            <Field
              name="actionCondition"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.CONDITION')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <StepTypeField
              values={values}
              isPendingGetValidators={isPendingGetValidators}
              lastUpdatedGetValidators={lastUpdatedGetValidators}
              validators={validators}
              form={form}
              flowId={flowId}
              initialValues={initialValues}
              readOnly={readOnly}
              reportFields={reportFields}
              isFirstNode={isFirstNode}
            />
            {(values.type === STEP_TYPES.STATIC_MESSAGE || values.type === STEP_TYPES.ASK_QUESTION) && values.isNewNode
              && (
              <Field
                name="createCondition"
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.CREATE_CONDITION')}
                component={SwitchField}
                styles={stylesSwitchField}
                switchProps={{
                  className: 'edit-node-action-form__switch',
                }}
                initialValue="true"
                readOnly={readOnly}
              />
              )}
            {
              (values.type !== STEP_TYPES.RESET_CHAT_AND_START_OVER
                && values.type !== STEP_TYPES.HANDOVER_TO_HUMAN
                && values.type !== STEP_TYPES.END_CHAT
              ) && (
                <>
                  <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings">
                    {t('SCENARIOS_CONSTRUCTOR.INSTANCES.AFTER_STEP')}
                  </h2>
                  <Field
                    name="timeout"
                    type="border"
                    label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.TIMEOUT')}
                    component={BasicField}
                    styles={stylesBasicField}
                    pattern={inputPatterns.numbers()}
                    readOnly={readOnly}
                  />
                  <Field
                    name="failureAction"
                    type="border"
                    label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.ON_FAILURE')}
                    component={DropdownFieldNew}
                    styles={stylesDropdownField}
                    options={ACTION_FAILURE_OPTIONS}
                    readOnly={readOnly}
                    initialValue="null"
                  />
                </>
              )
            }
            <Field
              name="reportField"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.REPORTING')}
              component={ReportField}
              options={reportFields}
              readOnly={readOnly}
            />
            <div className="edit-node-action-form__buttons">
              {
                (!initialValues.isNewNode && !readOnly && !isFirstNode) && (
                  <BasicButton
                    text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.DELETE_STEP')}
                    type={BasicButton.types.WARN}
                    icon={trashIcon}
                    filterType="only-white"
                    onClick={onDelete}
                  />
                )
              }
              <div className="edit-node-action-form__buttons__right">
                <BasicButton
                  text={t('CONTROLS.CANCEL')}
                  type={BasicButton.types.CANCEL}
                  icon={cancelIcon}
                  onClick={onCancel}
                  disabled={submitting}
                  isNewStyle
                />
                {
                  !readOnly && (
                    <BasicButton
                      text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.SAVE_STEP')}
                      type={BasicButton.types.ACCENT}
                      icon={saveIcon}
                      onClick={handleSubmit}
                      disabled={invalid}
                      loading={submitting}
                      isNewStyle
                    />
                  )
                }
              </div>
            </div>
          </form>
        )
      }
    />
  );
};

export default EditNodeActionForm;
