import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { toast, base64, getChannelType } from 'helpers';
import { useFlowsNewApi, useFlowResourceApi } from 'hooks/api';
import { RCS_TYPE } from 'const';
import { STEP_TYPES, TRIGGER_TYPES } from 'const/scenario';
import { CHANNELS } from 'const/channelsNew';
import rollbackIcon from 'assets/icons/rollback.svg';
import optOutIcon from 'assets/icons/opt-out.svg';
import attributeIcon from 'assets/icons/attribute.svg';
import codeIcon from 'assets/icons/code.svg';
import variableIcon from 'assets/icons/variable.svg';
import humanIcon from 'assets/icons/human.svg';
import staticMessageIcon from 'assets/icons/static-message.svg';
import dynamicMessageIcon from 'assets/icons/dynamic-message.svg';
import jumpToIcon from 'assets/icons/jump-to.svg';
import helpIcon from 'assets/icons/help.svg';
import replyIcon from 'assets/icons/reply-button.svg';
import actionIcon from 'assets/icons/action-button.svg';
import locationIcon from 'assets/icons/rcs/location-dark.svg';
import menuListIcon from 'assets/icons/whatsapp/widget-header.svg';
import locateIcon from 'assets/icons/locate.svg';

import PortInWidget from '../PortIn/PortInWidget';
import PortWidget from '../Port/PortWidget';

import './style.scss';

const languagesBackToForm = {
  2: 'javascript',
  1: 'python',
};
const messageTypes = {
  5: RCS_TYPE.richCard.toLowerCase(),
  6: RCS_TYPE.richCardCarousel.toLowerCase(),
  1: RCS_TYPE.text.toLowerCase(),
};

const ActionNodeWidget = ({
  node,
  engine,
}) => {
  const {
    entity: {
      label,
      type,
      settings,
      triggerType,
      selected,
    } = {},
  } = node;
  const { t } = useTranslation();

  const { flowsAll } = useFlowsNewApi();
  const { flowResource } = useFlowResourceApi();

  const onCrossClick = () => {
    if (triggerType !== 0) {
      engine.fireEvent({ node }, 'deleteNode');
    } else {
      toast.error(t('TOAST.START_NODE_DELETE'));
    }
  };
  const onCloneNode = () => {
    engine.fireEvent({ node }, 'nodeCloned');
  };

  const icon = useMemo(() => {
    switch (type) {
      case STEP_TYPES.STATIC_MESSAGE: {
        return staticMessageIcon;
      }
      case STEP_TYPES.DYNAMIC_MESSAGE: {
        return dynamicMessageIcon;
      }
      case STEP_TYPES.ASK_QUESTION: {
        return helpIcon;
      }
      case STEP_TYPES.SET_VARIABLE: {
        return variableIcon;
      }
      case STEP_TYPES.SET_CONTACT_ATTRIBUTE: {
        return attributeIcon;
      }
      case STEP_TYPES.MAKE_WEBHOOK_CALL: {
        return 1;
      }
      case STEP_TYPES.EXECUTE_CODE: {
        return codeIcon;
      }
      case STEP_TYPES.JUMP_TO: {
        return jumpToIcon;
      }
      case STEP_TYPES.RESET_CHAT_AND_START_OVER: {
        return rollbackIcon;
      }
      case STEP_TYPES.HANDOVER_TO_HUMAN: {
        return humanIcon;
      }
      case STEP_TYPES.OPT_OUT_USER: {
        return optOutIcon;
      }
      default: return 0;
    }
  }, [type]);
  const body = useMemo(() => {
    switch (type) {
      case STEP_TYPES.STATIC_MESSAGE:
      case STEP_TYPES.ASK_QUESTION: {
        const channelType = getChannelType(settings?.message?.content);
        switch (settings?.message?.messageType) {
          case 5: {
            // eslint-disable-next-line max-len
            const { content } = settings.message.content.RCSMessage.richcardMessage.message.generalPurposeCard;
            return {
              images: content.media?.thumbnailUrl ? [content.media.thumbnailUrl] : null,
              title: content.title,
              description: content.description,
              imagesCount: 'one',
              threadVariable: settings?.threadVariable,
              validatorName: settings?.validatorName,
            };
          }
          case 6: {
            // eslint-disable-next-line max-len
            const { content } = settings.message.content.RCSMessage.richcardMessage.message.generalPurposeCardCarousel;
            const carousel = content.slice(0, 3);
            const images = carousel.map(({ media }) => media?.thumbnailUrl);

            switch (carousel.length) {
              case 1: {
                return {
                  images,
                  title: carousel[0].title,
                  description: carousel[0].description,
                  imagesCount: 'one',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }
              case 2: {
                return {
                  images,
                  title: carousel[0].title,
                  description: carousel[0].description,
                  imagesCount: 'two',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }
              case 3: {
                return {
                  images,
                  title: carousel[1].title,
                  description: carousel[1].description,
                  imagesCount: 'three',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }
              default: return {};
            }
          }
          case 1: {
            return {
              description: settings.message.content.RCSMessage.textMessage,
              threadVariable: settings?.threadVariable,
              validatorName: settings?.validatorName,
            };
          }
          default: {
            if (channelType === CHANNELS.SMS) {
              return {
                description: settings?.message?.content,
                threadVariable: settings?.threadVariable,
                validatorName: settings?.validatorName,
              };
            }
            if (channelType === CHANNELS.WHATSAPP) {
              switch (true) {
                case settings?.message?.content?.interactive?.selectedType === 'whatsAppCard': {
                  let headerParams = {};
                  if (settings?.message?.content?.interactive?.header?.image?.link) {
                    headerParams = {
                      images: [settings?.message?.content?.interactive?.header?.image?.link],
                    };
                  }
                  if (settings?.message?.content?.interactive?.header?.text) {
                    headerParams = {
                      title: settings?.message?.content?.interactive?.header?.text,
                    };
                  }
                  return {
                    ...headerParams,
                    description: settings?.message?.content?.interactive?.body?.text,
                    imagesCount: 'one',
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                }
                case settings?.message?.content?.selectedType === 'whatsAppText':
                case settings?.message?.content?.type === 'text':
                  return {
                    description: settings?.message?.content?.text?.body,
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                case settings?.message?.content?.selectedType === 'whatsAppFile':
                  return {
                    images: [settings?.message?.content?.image?.link],
                    imagesCount: 'one',
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                case settings?.message?.content?.type === 'image':
                  return {
                    images: [settings?.message?.content?.thumbnailUri],
                    imagesCount: 'one',
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                case settings?.message?.content?.interactive?.selectedType === 'whatsAppSendGeolocation':
                  return {
                    illustration: locateIcon,
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                    description: settings?.message?.content?.interactive?.body?.text,
                  };
                case settings?.message?.content?.type === 'location':
                  return {
                    illustration: locationIcon,
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                case settings?.message?.content?.type === 'template':
                  return {
                    description: `${t('MESSAGE_CONSTRUCTOR.TEMPLATE')}: ${settings?.message?.content?.template?.name}`,
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                case settings?.message?.content?.interactive?.selectedType === 'whatsAppMenu':
                  return {
                    illustration: menuListIcon,
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
                default:
                  return {
                    description: '',
                    threadVariable: settings?.threadVariable,
                    validatorName: settings?.validatorName,
                  };
              }
            }
            if (channelType === CHANNELS.VIBER) {
              // image card
              if (settings?.message?.content?.imageUrl && settings?.message?.content?.buttonCaption) {
                return {
                  images: [settings?.message?.content?.imageUrl],
                  description: settings?.message?.content?.text,
                  imagesCount: 'one',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }
              // image
              if (settings?.message?.content?.imageUrl) {
                return {
                  images: [settings?.message?.content?.imageUrl],
                  imagesCount: 'one',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }
              // text/text card
              return {
                description: settings?.message?.content?.text,
                threadVariable: settings?.threadVariable,
                validatorName: settings?.validatorName,
              };
              // q;
            }
            if (channelType === CHANNELS.WECHAT) {
              if (settings?.message?.content?.fileUrl) {
                return {
                  images: [settings?.message?.content?.fileUrl],
                  imagesCount: 'one',
                  threadVariable: settings?.threadVariable,
                  validatorName: settings?.validatorName,
                };
              }

              return {
                description: settings?.message?.content?.Content,
                threadVariable: settings?.threadVariable,
                validatorName: settings?.validatorName,
              };
            }

            return {};
          }
        }
      }
      case STEP_TYPES.DYNAMIC_MESSAGE: {
        return dynamicMessageIcon;
      }
      // case STEP_TYPES.ASK_QUESTION: {
      //   return {
      //     question: settings?.message?.content?.RCSMessage?.textMessage,
      //     threadVariable: settings?.threadVariable,
      //     validatorName: settings?.validatorName,
      //   };
      // }
      // case STEP_TYPES.SET_VARIABLE: {
      //   return variableIcon;
      // }
      // case STEP_TYPES.SET_CONTACT_ATTRIBUTE: {
      //   return attributeIcon;
      // }
      // case STEP_TYPES.MAKE_WEBHOOK_CALL: {
      //   return 1;
      // }
      case STEP_TYPES.EXECUTE_CODE: {
        try {
          // eslint-disable-next-line no-undef
          const code = base64.decode(settings?.code?.code);
          return {
            code,
            language: languagesBackToForm[settings?.code?.language],
          };
        } catch (err) {
          // console.error(t('SCENARIOS_CONSTRUCTOR.INSTANCES.PARSE_ERROR'));
          return {};
        }
      }
      case STEP_TYPES.JUMP_TO: {
        const flowId = settings?.flowId;
        const nodeId = settings?.nodeId;

        return {
          flowId,
          nodeId,
          flowName: flowsAll.find(I => I.id === flowId)?.name,
          nodeName: flowResource[flowId]?.nodes?.find(I => I.id === nodeId)?.label,
        };
      }
      case STEP_TYPES.RESET_CHAT_AND_START_OVER:
      case STEP_TYPES.HANDOVER_TO_HUMAN: {
        return {};
      }
      // case STEP_TYPES.END_CHAT: {
      //   return 1;
      // }
      default: return {};
    }
  }, [type, settings]);
  const labelNode = useMemo(() => {
    switch (triggerType) {
      case TRIGGER_TYPES.START:
        return {
          class: 'start',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.START',
        };
      case TRIGGER_TYPES.FAILOVER:
        return {
          class: 'failover',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.FAILOVER',
        };
      case TRIGGER_TYPES.RESPONSE_TIMEOUT:
        return {
          class: 'response-timeout',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.RESP_TIMEOUT',
        };
      case TRIGGER_TYPES.STUCK_TIMEOUT:
        return {
          class: 'stuck-timeout',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.STUCK_TIMEOUT',
        };
      case TRIGGER_TYPES.OPT_OUT:
        return {
          class: 'opt-out',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT',
        };
      default: return {};
    }
  }, [triggerType]);

  const onDoubleClick = () => {
    engine.fireEvent({ node }, 'onClickNode');
  };

  const containerClass = classNames('action-node', {
    'action-node_selected': selected,
    'action-node_handover-to-human': type === STEP_TYPES.HANDOVER_TO_HUMAN,
    'action-node_reset-chat': type === STEP_TYPES.RESET_CHAT_AND_START_OVER,
    'action-node_opt-out': type === STEP_TYPES.OPT_OUT_USER,
  });

  const labelClass = classNames('action-node__label', {
    [`action-node__label_${labelNode.class}`]: !!labelNode.class,
  });

  const {
    actionsCount,
    repliesCount,
  } = useMemo(() => {
    if (type === STEP_TYPES.ASK_QUESTION || type === STEP_TYPES.STATIC_MESSAGE) {
      // if WhatsApp interactive card
      if (settings?.message?.content?.interactive?.action?.buttons?.length) {
        return {
          repliesCount: settings?.message?.content?.interactive?.action?.buttons?.length,
          actionsCount: 0,
        };
      }
      // Если у нас карточка text/image card (viber), возвращаем 1 reply
      if (settings?.message?.content?.buttonCaption) {
        return { repliesCount: 1, actionsCount: 0 };
      }

      // в остальных случаях работаем с RCS
      const rcsMessage = settings?.message?.content?.RCSMessage;
      const rcsMessageKeys = Object.keys(rcsMessage ?? {});
      const suggestionsOut = rcsMessage?.suggestedChipList?.suggestions || [];
      let rcsType = '';

      if (rcsMessageKeys.length) {
        switch (true) {
          case rcsMessageKeys.includes('richcardMessage'):
            rcsType = 'richcardMessage';
            break;
          case rcsMessageKeys.includes('fileMessage'):
            rcsType = 'fileMessage';
            break;
          case rcsMessageKeys.includes('textMessage'):
            rcsType = 'textMessage';
            break;
          default: break;
        }
      }

      if (rcsType === 'richcardMessage'
        && rcsMessage.richcardMessage.message.generalPurposeCardCarousel) {
        rcsType = 'carousel';
      }

      const calcSuggestions = (suggestionsInside = []) => {
        const counter = { actionsCount: 0, repliesCount: 0 };

        suggestionsOut.forEach((I) => {
          if (I.reply) {
            counter.repliesCount += 1;
          } else {
            counter.actionsCount += 1;
          }
        });
        suggestionsInside.forEach((I) => {
          if (I.reply) {
            counter.repliesCount += 1;
          } else {
            counter.actionsCount += 1;
          }
        });

        return counter;
      };

      switch (rcsType) {
        case 'fileMessage':
          return calcSuggestions();
        case 'textMessage':
          return calcSuggestions();
        case 'richcardMessage':
          return calcSuggestions(
            rcsMessage.richcardMessage?.message?.generalPurposeCard?.content?.suggestions,
          );
        case 'carousel': {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { content } = rcsMessage.richcardMessage?.message?.generalPurposeCardCarousel;
          const suggestionsInside = content.reduce((acc, item) => {
            if (item?.suggestions) {
              acc.push(...item.suggestions);
            }
            return acc;
          }, []);

          return calcSuggestions(suggestionsInside);
        }
        default: return { actionsCount: 0, repliesCount: 0 };
      }
    }
    return { actionsCount: 0, repliesCount: 0 };
  }, [settings]);
  const suggestionsExist = useMemo(() => {
    if (type === STEP_TYPES.ASK_QUESTION || type === STEP_TYPES.STATIC_MESSAGE) {
      const rcsMessage = settings?.message?.content?.RCSMessage;
      if (rcsMessage && rcsMessage?.richcardMessage?.message?.generalPurposeCardCarousel?.content) {
        // eslint-disable-next-line
        const suggestions = rcsMessage.richcardMessage.message.generalPurposeCardCarousel.content?.map(el => el.suggestions).filter(el => el).flat();
        return suggestions?.some(el => el?.reply || el?.action);
      }
      if (rcsMessage && rcsMessage.richcardMessage) {
        return rcsMessage.richcardMessage.message
          ?.generalPurposeCard?.content?.suggestions?.some(el => el?.reply || el?.action);
      }
      if (rcsMessage && rcsMessage.suggestedChipList) {
        return rcsMessage.suggestedChipList?.suggestions?.some(el => el?.reply || el?.action);
      }
    }
    return null;
  }, [settings]);

  return (
    <div
      className={containerClass}
      onDoubleClick={onDoubleClick}
    >
      {
        !engine.getModel().isLocked() && (
          <button
            data-id="delete-button"
            aria-label="delete"
            className="action-node__delete"
            type="button"
            onClick={onCrossClick}
          />
        )
      }
      {
        !engine.getModel().isLocked() && (
          <button
            data-id="clone-button"
            aria-label="clone"
            className="action-node__clone"
            type="button"
            onClick={onCloneNode}
          />
        )
      }
      {
        triggerType !== null && (
          <div className={labelClass}>
            {t(labelNode.textKey)}
          </div>
        )
      }
      <div className="action-node__header">
        <div className="action-node__header__ports-in">
          <div className="action-node__header__ports-in__left">
            <PortInWidget
              engine={engine}
              port={node.getPort('in-1')}
            />
          </div>
          <div className="action-node__header__ports-in__center">
            {
              !!node.getPort('in-2') && (
                <PortInWidget
                  engine={engine}
                  port={node.getPort('in-2')}
                />
              )
            }
          </div>
          <div className="action-node__header__ports-in__right">
            <PortInWidget
              engine={engine}
              port={node.getPort('in-3')}
            />
          </div>
        </div>
        <img
          className="action-node__header__icon"
          src={icon}
          alt=""
        />
        <span className="action-node__header__label">
          {label}
        </span>
      </div>
      <div className="action-node__content">
        {
          type === STEP_TYPES.DYNAMIC_MESSAGE && (
            <>
              <div className={`action-node__content__dynamic-message action-node__content__dynamic-message_${messageTypes[settings.message.messageType]}`}>
                <div className="action-node__content__dynamic-message__image">
                  <div className="action-node__content__dynamic-message__image__prev" />
                  <div className="action-node__content__dynamic-message__image__current" />
                  <div className="action-node__content__dynamic-message__image__next" />
                </div>
                <div className="action-node__content__dynamic-message__content">
                  <div className="action-node__content__dynamic-message__content__title" />
                  <div className="action-node__content__dynamic-message__content__text" />
                  <div className="action-node__content__dynamic-message__content__text" />
                </div>
              </div>
              <div className="action-node__content__crop-block" />
            </>
          )
        }
        {
          type === STEP_TYPES.MAKE_WEBHOOK_CALL && (
            <>
              <span className="action-node__content__text">
                http://domain.zone/?webhook
              </span>
              <div className="action-node__content__crop-block" />
            </>
          )
        }
        {
          type === STEP_TYPES.EXECUTE_CODE && (
            <>
              <span className="action-node__content__text">
                {body.code}
              </span>
              <div className="action-node__content__additional-block">
                <span className="action-node__content__additional-block__row">
                  {'language: '}
                  <span className="action-node__content__additional-block__row__value">
                    {body.language}
                  </span>
                </span>
              </div>
            </>
          )
        }
        {
          type === STEP_TYPES.ASK_QUESTION && (
            <>
              <div className="action-node__content__static-message">
                {
                  body.images && (
                    <div className={`action-node__content__static-message__images action-node__content__static-message__images_${body.imagesCount}`}>
                      {body.images.map((image, index) => (
                        <img
                          // eslint-disable-next-line
                          key={`${image}-${index}`}
                          className="action-node__content__static-message__images__image"
                          alt=""
                          src={image}
                        />
                      ))}
                    </div>
                  )
                }
                <div className="action-node__content__static-message__content">
                  {
                    body.title && (
                      <span className="action-node__content__static-message__content__title">
                        {body.title}
                      </span>
                    )
                  }
                  <span className="action-node__content__static-message__content__description">
                    {body.description}
                  </span>
                  <div className="action-node__content__action-reply">
                    {suggestionsExist && (
                    <>
                      <div className="action-node__content__action-reply__item" />
                      <div className="action-node__content__action-reply__item" />
                    </>
                    )}
                  </div>
                </div>
              </div>
              <div className="action-node__content__additional-block">
                {
                  body.validatorName && (
                    <span className="action-node__content__additional-block__row">
                      {'expecting: '}
                      <span className="action-node__content__additional-block__row__value">
                        {body.validatorName}
                      </span>
                    </span>
                  )
                }
                {
                  body.threadVariable && (
                    <span className="action-node__content__additional-block__row">
                      {'save to: '}
                      <span className="action-node__content__additional-block__row__variable">
                        {body.threadVariable}
                      </span>
                    </span>
                  )
                }
              </div>
            </>
          )
        }
        {
          type === STEP_TYPES.STATIC_MESSAGE && (
            <>
              <div className="action-node__content__static-message">
                {
                  body?.images && (
                    <div className={`action-node__content__static-message__images action-node__content__static-message__images_${body.imagesCount}`}>
                      {body?.images?.map((image, index) => (
                        <img
                          // eslint-disable-next-line
                          key={`${image}-${index}`}
                          className="action-node__content__static-message__images__image"
                          alt=""
                          src={image}
                        />
                      ))}
                    </div>
                  )
                }
                {
                  body?.illustration && (
                    <div className="action-node__content__others">
                      <img
                        alt=""
                        className="action-node__content__others__illustration"
                        src={body?.illustration}
                      />
                    </div>
                  )
                }
                <div className="action-node__content__static-message__content">
                  {
                    body?.title && (
                      <span className="action-node__content__static-message__content__title">
                        {body?.title}
                      </span>
                    )
                  }
                  <span className="action-node__content__static-message__content__description">
                    {body?.description}
                  </span>
                  {
                    (!!actionsCount || !!repliesCount) && (
                      <div className="action-node__content__suggestions">
                        {
                          !!repliesCount && (
                            <div className="action-node__content__suggestions__item">
                              <div className="action-node__content__suggestions__button">
                                <img src={replyIcon} alt="" />
                                Reply
                              </div>
                              {`x${repliesCount}`}
                            </div>
                          )
                        }
                        {
                          !!actionsCount && (
                            <div className="action-node__content__suggestions__item">
                              <div className="action-node__content__suggestions__button">
                                <img src={actionIcon} alt="" />
                                Action
                              </div>
                              {`x${actionsCount}`}
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="action-node__content__crop-block" />
            </>
          )
        }
        {
          type === STEP_TYPES.RESET_CHAT_AND_START_OVER && (
            <div className="action-node__content__others">
              <img
                alt=""
                className="action-node__content__others__illustration"
                src={rollbackIcon}
              />
              <span className="action-node__content__others__label">
                Reset chat and start over
              </span>
            </div>
          )
        }
        {
          type === STEP_TYPES.HANDOVER_TO_HUMAN && (
            <div className="action-node__content__others">
              <img
                alt=""
                className="action-node__content__others__illustration"
                src={humanIcon}
              />
              <span className="action-node__content__others__label">
                Handover to human
              </span>
            </div>
          )
        }
        {
          type === STEP_TYPES.OPT_OUT_USER && (
            <div className="action-node__content__others">
              <img
                alt=""
                className="action-node__content__others__illustration"
                src={optOutIcon}
              />
              <span className="action-node__content__others__label">
                Opt-Out user
              </span>
            </div>
          )
        }
        {
          type === STEP_TYPES.JUMP_TO && (
            <>
              <img
                alt=""
                className="action-node__content__others__illustration"
                src={jumpToIcon}
                style={{ margin: '10px auto 0' }}
              />
              <span className="action-node__content__others__label" style={{ marginBottom: 10, textAlign: 'center' }}>
                Jump to..
              </span>
              {
                (body.flowId || body.nodeId) && (
                  <div className="action-node__content__additional-block">
                    <span className="action-node__content__additional-block__row">
                      {'scenario: '}
                      <span className="action-node__content__additional-block__row__value">
                        {`${body.flowName} (ID ${body.flowId})` || `ID ${body.flowId}`}
                      </span>
                    </span>
                    {
                      body.nodeId && (
                        <span className="action-node__content__additional-block__row">
                          {'node: '}
                          <span className="action-node__content__additional-block__row__value">
                            {body.nodeName || `ID ${body.nodeId}`}
                          </span>
                        </span>
                      )
                    }
                  </div>
                )
              }
            </>
          )
        }
        {
          node.getOutPorts().map(port => (
            <PortWidget
              key={port.getID()}
              engine={engine}
              port={port}
            />
          ))
        }
      </div>
    </div>
  );
};

export default ActionNodeWidget;
