import {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { isEmpty } from 'lodash';

import { getFiltersForQuery } from 'helpers';

import usePagination from './usePagination';

export default ({
  sendQuery,
  withStatePagination = false,
  initialLimit = 100,
  tableRef = null,
  sessionKey = window.location.pathname,
  initialFilters,
}) => {
  const initialProps = useMemo(
    () =>
      JSON.parse(window.sessionStorage.getItem(`filters_${sessionKey}`)),
    [sessionKey],
  );

  let limit;
  let offset;
  let setLimit;
  let setOffset;
  let resetPagination;

  // пагинация
  if (withStatePagination) {
    [offset, setOffset] = useState(1);
    [limit, setLimit] = useState(initialLimit);

    resetPagination = () => setOffset(1);
  } else {
    const props = usePagination({ initialLimit });
    limit = props.limit;
    offset = props.offset;
    setLimit = props.onChangeLimit;
    setOffset = props.onChangePage;
    resetPagination = props.resetPagination;
  }

  const sort = useRef(null);
  const filters = useRef(initialProps || initialFilters || null);

  const handleQuery = () => {
    let queries = {
      limit,
      offset,
    };

    if (filters.current) {
      queries = {
        ...queries,
        ...getFiltersForQuery(filters.current),
      };
    }

    if (sort.current) {
      queries.sort = `${sort.current.type === 'desc' ? '-' : ''}${sort.current.name}`;
    }

    sendQuery({
      ...queries,
    });
  };

  const onFilterChanged = (newFilters) => {
    if (isEmpty(newFilters)) {
      filters.current = null;
    } else {
      filters.current = newFilters;
    }

    if (tableRef) {
      window.sessionStorage.setItem(`filters_${sessionKey}`, JSON.stringify(filters.current));
    }

    if (offset !== 1) {
      resetPagination();
    } else {
      handleQuery();
    }
  };
  const onSortChanged = (newSort) => {
    if (isEmpty(newSort)) {
      sort.current = null;
    } else {
      sort.current = {
        name: newSort[0].colId,
        type: newSort[0].sort,
      };
    }

    handleQuery();
  };
  const setFilters = (newFilters) => {
    filters.current = newFilters;
  };

  useEffect(() => {
    handleQuery();
  }, [limit, offset]);

  useEffect(() => {
    if (tableRef) {
      setTimeout(() => {
        tableRef.current.setFilters(initialProps || initialFilters);
      }, 500);
    }
  }, []);

  return {
    onFilterChanged,
    onSortChanged,
    setFilters,
    sort: sort.current,
    sortRef: sort,
    filters: filters.current,
    filtersRef: filters,
    offset,
    limit,
    setOffset,
    setLimit,
    resetPagination,
    sendQuery: handleQuery,
  };
};
