import React, {
  forwardRef,
  useMemo,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import createEngine, { DiagramModel } from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';

import { STEP_TYPES, NODE_TYPE, CONDITION_NODE_TYPE } from 'const/scenario';
import { buttons } from 'components';
import { BasicButton, IconButton } from 'components/buttons';
import { SwitchField } from 'components/fields';
import Tooltip from 'components/Tooltip';
import { base64 } from 'helpers';
import lightningIcon from 'assets/icons/lightning.svg';
import arrowsTwoIcon from 'assets/icons/arrows-two.svg';
import plusSquaredIcon from 'assets/icons/plus-squared.svg';
import minusSquaredIcon from 'assets/icons/minus-squared.svg';
import preModerationIcon from 'assets/icons/controls/pre-moderation.svg';
import finishIcon from 'assets/icons/finish.svg';
import { useFlowsNewApi, useCompaniesApi } from 'hooks/api';

import ConditionNodeFactory from './ConditionNode/ConditionNodeFactory';
import ConditionNodeModel from './ConditionNode/ConditionNodeModel';
import ActionNodeFactory from './ActionNode/ActionNodeFactory';
import ActionNodeModel from './ActionNode/ActionNodeModel';
import LinkFactory from './Link/LinkFactory';
import PortFactory from './Port/PortFactory';
import States from './States';
import ZoomCanvasAction from './actions/ZoomCanvasAction';

import './style.scss';

const languagesBackToForm = {
  2: 'javascript',
  1: 'python',
};

export default forwardRef(({
  // nodes,
  onClickNode,
  addNewNode,
  initialValues,
  nodeRemoved,
  nodeCloned,
  deleteNode,
  onUpdateNode,
  onUpdateLink,
  onRemoveLink,
  readOnlyAlways = false,
  currentNodeId,
  initialZoom = 70,
  validators,
  sendQuery = () => {},
}, ref) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(initialZoom);
  const [isEditMode, setIsEditMode] = useState(false);
  const scrollRef = useRef(null);

  const {
    flowById,
    getFlowToggleModerate,
    isPendingGetFlowToggleModerate,
    isPendingGetFlowById,
  } = useFlowsNewApi();

  const {
    ownCompany,
    getOwnCompany,
  } = useCompaniesApi();

  const engine = useMemo(() => {
    const nextEngine = createEngine();
    nextEngine.getNodeFactories().registerFactory(new ConditionNodeFactory());
    nextEngine.getNodeFactories().registerFactory(new ActionNodeFactory());
    nextEngine.getLinkFactories().registerFactory(new LinkFactory());
    nextEngine.getPortFactories().registerFactory(new PortFactory());
    nextEngine.getStateMachine().pushState(new States());
    nextEngine.getActionEventBus().registerAction(new ZoomCanvasAction());

    const model = new DiagramModel();

    model.setZoomLevel(initialZoom);
    model.registerListener({
      zoomUpdated: throttle((e) => {
        setZoom(Math.trunc(e.zoom));
      }, 500),
      portUpdated: ({ port }) => {
        onUpdateLink({
          id: port.options.id,
          condition: port.options.condition,
          isTerminal: !!port.options.isFinal,
          fromNodeId: port.getNode().getID(),
          toNodeId: port.getTargetNodeId(),
          label: port.options.condition ? port.options.condition[0].label : '',
          reportField: port.options.condition ? port.options.condition[0].reportField || undefined
            : undefined,
          meta: {
            portName: port.getTargetPortName(),
          },
        });
      },
      portRemoved: ({ port }) => {
        onRemoveLink({ id: port.options.id });
      },
      nodeMoved: ({ node }) => {
        // todo из-за порта, проверить актуально ли
        if (!node) {
          return;
        }

        onUpdateNode({
          id: node.getID(),
          ...node.entity,
          meta: {
            position: node.getPosition(),
          },
        });
      },
      nodeRemoved: ({ node }) => {
        nodeRemoved(node);
      },
      nodeCloned: ({ node }) => {
        nodeCloned(node.getID());
      },
    });

    nextEngine.setModel(model);

    return nextEngine;
  }, []);

  useImperativeHandle(ref, () => ({
    updateNode: ({ id, entity }) => {
      const node = engine.getModel().getNode(id);
      node.entity = { ...(node.entity || {}), ...entity };
    },
    removeNode: ({ id }) => {
      engine.getModel().getNode(id).remove();
    },
    addNode: ({
      point,
      type,
      entity,
      id,
      withoutTopPort,
    }) => {
      const NodeModel = type === NODE_TYPE.CONDITION ? ConditionNodeModel : ActionNodeModel;
      const node = new NodeModel({ entity, id, withoutTopPort });

      node.setPosition(point);
      engine.getModel().addNode(node);
      engine.repaintCanvas();
    },
    addOutPort: ({ nodeId, ...options }) => {
      engine.getModel().getNode(nodeId).addOutPort(options);
    },
    updatePort: ({ nodeId, id, ...options }) => {
      engine.getModel().getNode(nodeId).getPort(id).updatePort(options);
    },
    removePort: ({ nodeId, id }) => {
      const node = engine.getModel().getNode(nodeId);
      node.removePort(node.getPort(id));
    },
    getEngine: () => engine,
    getNodeOutPorts: ({ id }) => engine.getModel().getNode(id).getOutPorts(),
    repaintCanvas: () => engine.repaintCanvas(),
  }));

  const onClickIncrementZoom = () => {
    engine.getModel().setZoomLevel(engine.getModel().getZoomLevel() + 5);
  };
  const onClickDecrementZoom = () => {
    const currentZoomValue = engine.getModel().getZoomLevel();
    const nextZoomValue = currentZoomValue > 5 ? currentZoomValue - 5 : 1;
    engine.getModel().setZoomLevel(nextZoomValue);
  };
  const onDrop = (event) => {
    const data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
    const point = engine.getRelativeMousePoint(event);

    engine.getModel().clearSelection();
    addNewNode(data.type, point);
    event.preventDefault();
  };

  useEffect(() => {
    const stopScroll = e => e.preventDefault();
    scrollRef.current.addEventListener('wheel', stopScroll);

    return () => scrollRef.current.removeEventListener('wheel', stopScroll);
  }, []);
  useEffect(() => {
    engine.registerListener({
      onClickNode: ({ node }) => {
        node.entity.selected = true;
        engine.repaintCanvas();

        const currentNode = {
          ...node.entity,
          ...(node.entity.settings || {}),
          position: node.position,
          id: node.options.id,
          triggerType: node.entity.triggerType === null ? 'null' : node.entity.triggerType,
          readOnly: node.isLocked(),
        };

        switch (node.entity.nodeClass) {
          case NODE_TYPE.CONDITION: {
            const conditions = node.getOutPorts().map(port => port.getOptions().condition);

            switch (node.entity.type) {
              case CONDITION_NODE_TYPE.USER_RESPONSE: {
                currentNode.userResponses = conditions;
                break;
              }
              case CONDITION_NODE_TYPE.CONDITION: {
                currentNode.conditions = conditions.map(condition => condition[0]);
                break;
              }
              default: break;
            }
            break;
          }
          case NODE_TYPE.ACTION: {
            currentNode.failureAction = node.entity.failureAction === null ? 'null' : node.entity.failureAction;
            // parse back data to form data
            switch (currentNode.type) {
              case STEP_TYPES.STATIC_MESSAGE: {
                if (node.entity.settings?.message) {
                  currentNode.isTypingDelay = node.entity.settings.message.isTypingDelay;
                  currentNode.content = node.entity.settings.message.content;
                }
                break;
              }
              case STEP_TYPES.DYNAMIC_MESSAGE: {
                const message = node.entity.settings?.message;

                if (message) {
                  if (message.code) {
                    currentNode.executeCodeDynamicMessage = {};

                    if (message.code.language) {
                      currentNode.executeCodeDynamicMessage.language = (
                        languagesBackToForm[message.code.language]
                      );
                    }

                    if (message.code.code) {
                      // eslint-disable-next-line max-len
                      currentNode.executeCodeDynamicMessage.code = base64.decode(message.code.code);
                    }
                  }

                  currentNode.isTypingDelay = message.isTypingDelay;
                  currentNode.messageType = message.messageType;
                }
                break;
              }
              case STEP_TYPES.ASK_QUESTION: {
                if (node.entity.settings) {
                  currentNode.threadVariable = node.entity.settings.threadVariable;
                  currentNode.validatorId = node.entity.settings.validatorId;
                  currentNode.retryCount = node.entity.settings.retryCount === null ? 'null' : node.entity.settings.retryCount;

                  if (node.entity.settings.message) {
                    currentNode.delayQuestion = node.entity.settings.message.isTypingDelay;
                    currentNode.questionContent = node.entity.settings.message.content;
                  }

                  if (node.entity.settings.retryMessage) {
                    currentNode.delayRetry = node.entity.settings.retryMessage.isTypingDelay;
                    currentNode.retryContent = node.entity.settings.retryMessage.content;
                  }
                }
                break;
              }
              case STEP_TYPES.EXECUTE_CODE: {
                if (node.entity.settings) {
                  if (node.entity.settings.code) {
                    currentNode.executeCode = {};

                    if (node.entity.settings.code.language) {
                      currentNode.executeCode.language = (
                        languagesBackToForm[node.entity.settings.code.language]
                      );
                    }

                    if (node.entity.settings.code.code) {
                      currentNode.executeCode.code = base64.decode(node.entity.settings.code.code);
                    }
                  }

                  // currentNode.outParameters = node.outParameters;
                  // currentNode.inParameters = node.inParameters;
                }
                break;
              }
              case STEP_TYPES.JUMP_TO: {
                if (node.entity.settings) {
                  currentNode.passContext = node.entity.settings.passContext;
                  currentNode.selectedNodeId = node.entity.settings.nodeId === null ? 'null' : node.entity.settings.nodeId;
                  currentNode.selectedFlowId = node.entity.settings.flowId === null ? 'null' : node.entity.settings.flowId;
                }
                break;
              }
              default: break;
            }
            break;
          }
          default: break;
        }

        onClickNode(currentNode);
      },
    });
  }, [onClickNode]);
  useEffect(() => {
    engine.registerListener({
      deleteNode: ({ node }) => {
        deleteNode(node);
      },
      nodeCloned: ({ node }) => {
        nodeCloned({
          id: node.options.id,
          successCallback: ({ node: item, links = [] }) => {
            const canvasModel = engine.getModel();

            const NodeModel = item.nodeClass === NODE_TYPE.CONDITION
              ? ConditionNodeModel
              : ActionNodeModel;
            const entity = item;

            switch (item.type) {
              case STEP_TYPES.ASK_QUESTION: {
                const foundValidator = validators
                  .find(validator => validator.id === item.settings.validatorId);

                if (foundValidator) {
                  item.settings.validatorName = foundValidator.name;
                }
                break;
              }
              default: break;
            }

            canvasModel.addNode(
              new NodeModel({
                entity,
                id: item.id,
                withoutTopPort: item.triggerType !== null,
              }),
            ).setPosition(item.meta.position.x || 0, item.meta.position.y || 0);

            links.forEach((link) => {
              const sourcePort = canvasModel.getNode(link.fromNodeId).addOutPort({
                id: link.id,
                condition: link.condition,
                isFinal: link.isTerminal,
                isElse: !link.condition,
                withBottomCircle: !link.condition,
                prevState: JSON.stringify({
                  id: link.id,
                  fromNodeId: link.fromNodeId,
                  toNodeId: link.toNodeId,
                  condition: link.condition,
                  isFinal: link.isTerminal,
                }),
              });

              if (link.toNodeId && !link.isTerminal) {
                const targetNode = canvasModel.getNode(link.toNodeId);
                const newLink = sourcePort.link(targetNode.getPort(link.meta?.portName || 'in-1'), { condition: link.condition });
                canvasModel.addLink(newLink);
              }
            });

            engine.repaintCanvas();
          },
        });
      },
    });
    getOwnCompany();
  }, []);
  useEffect(() => {
    if (initialValues.nodes && initialValues.nodes.length) {
      const canvasModel = engine.getModel();
      initialValues.nodes.forEach(async (item) => {
        const NodeModel = item.nodeClass === NODE_TYPE.CONDITION
          ? ConditionNodeModel
          : ActionNodeModel;
        const entity = item;

        switch (item.type) {
          case STEP_TYPES.ASK_QUESTION: {
            const foundValidator = validators
              .find(validator => validator.id === item.settings.validatorId);

            if (foundValidator) {
              item.settings.validatorName = foundValidator.name;
            }
            break;
          }
          default: break;
        }

        await canvasModel.addNode(
          new NodeModel({
            entity,
            id: item.id,
            withoutTopPort: item.triggerType !== null,
          }),
        ).setPosition(item.meta.position.x || 0, item.meta.position.y || 0);
      });

      initialValues.links.forEach((link) => {
        if (initialValues.nodes.find(item => item.id === link.fromNodeId).nodeClass
        !== NODE_TYPE.CONDITION
        || link.condition) {
          const sourcePort = canvasModel.getNode(link.fromNodeId).addOutPort({
            id: link.id,
            condition: link.condition,
            isFinal: link.isTerminal,
            isElse: !link.condition,
            withBottomCircle: !link.condition,
            prevState: JSON.stringify({
              id: link.id,
              fromNodeId: link.fromNodeId,
              toNodeId: link.toNodeId,
              condition: link.condition,
              isFinal: link.isTerminal,
            }),
          });
          if (link.toNodeId && !link.isTerminal) {
            const targetNode = canvasModel.getNode(link.toNodeId);
            const newLink = sourcePort.link(targetNode.getPort(link.meta?.portName || 'in-1'), { condition: link.condition });
            canvasModel.addLink(newLink);
          }
        }
      });
    }
  }, [initialValues]);
  useEffect(() => {
    // используется на экране конверсаций,
    // для того что бы выбранная нода была по центру зоны просмотра
    if (initialValues.nodes && initialValues.nodes.length && currentNodeId) {
      setTimeout(() => {
        const canvas = engine.getCanvas();
        const canvasModel = engine.getModel();
        if (canvas) {
          const foundNode = canvasModel.getNodes().find(node => node.entity.selected);

          if (foundNode) {
            foundNode.entity = {
              ...foundNode.entity,
              selected: false,
            };
          }

          const node = canvasModel.getNode(currentNodeId);

          if (node) {
            node.entity = {
              ...node.entity,
              selected: true,
            };
            const nodePosition = node.getPosition();
            const percent = engine.getModel().getZoomLevel() / 100;
            // eslint-disable-next-line max-len
            const newOffsetX = (canvas.clientWidth - node.width * percent) / 2 - nodePosition.x * percent;
            // eslint-disable-next-line max-len
            const newOffsetY = (canvas.clientHeight - node.height * percent) / 2 - nodePosition.y * percent;

            canvasModel.setOffset(newOffsetX, newOffsetY);
          }

          engine.repaintCanvas();
        }
      });
    } else {
      setTimeout(() => {
        try {
          if (initialValues?.nodes?.length) {
            const canvas = engine.getCanvas();
            const canvasModel = engine.getModel();

            if (canvas) {
              const allNodes = engine.getModel().getSelectionEntities();

              // get nodes bounding box with margin
              const nodesRect = engine.getBoundingNodesRect(allNodes, 50);

              if (nodesRect) {
                // there is something we should zoom on
                const canvasRect = canvas.getBoundingClientRect();
                const canvasTopLeftPoint = {
                  x: canvasRect.left,
                  y: canvasRect.top,
                };
                const nodeLayerTopLeftPoint = {
                  x: canvasTopLeftPoint.x + canvasModel.getOffsetX(),
                  y: canvasTopLeftPoint.y + canvasModel.getOffsetY(),
                };

                const xFactor = canvas.clientWidth / nodesRect.getWidth();
                const yFactor = canvas.clientHeight / nodesRect.getHeight();
                let zoomFactor = xFactor < yFactor ? xFactor : yFactor;

                if (zoomFactor > 1) {
                  zoomFactor = 1;
                }

                canvasModel.setZoomLevel(zoomFactor * 100);

                const nodesRectTopLeftPoint = {
                  x: nodeLayerTopLeftPoint.x + nodesRect.getTopLeft().x * zoomFactor,
                  y: nodeLayerTopLeftPoint.y + nodesRect.getTopLeft().y * zoomFactor,
                };

                canvasModel.setOffset(
                  canvasModel.getOffsetX() + canvasTopLeftPoint.x - nodesRectTopLeftPoint.x,
                  canvasModel.getOffsetY() + canvasTopLeftPoint.y - nodesRectTopLeftPoint.y,
                );
                engine.repaintCanvas();
              }
            }
            // engine.zoomToFitNodes(50);
          }
        } catch (error) {
          console.log('error', error);
        }
      }, 100);
    }
  }, [currentNodeId]);
  useEffect(() => {
    engine.getModel().setLocked(!isEditMode);
    engine.repaintCanvas();
  }, [isEditMode]);

  const onModerateProcess = () => {
    getFlowToggleModerate({
      id: flowById?.id,
      successCallback: () => sendQuery(),
    });
  };

  return (
    <div className="canvas-widget">
      <div
        ref={scrollRef}
        onDrop={onDrop}
        onDragOver={event => event.preventDefault()}
        style={{ width: '100%', height: '100%' }}
      >
        <CanvasWidget
          className="diagram-container"
          engine={engine}
        />
      </div>
      <div className="canvas-widget__zoom">
        <IconButton
          onClick={onClickDecrementZoom}
          icon={minusSquaredIcon}
          filterType="white"
        />
        <span className="canvas-widget__zoom__current">
          {`${zoom}%`}
        </span>
        <IconButton
          onClick={onClickIncrementZoom}
          icon={plusSquaredIcon}
          filterType="white"
        />
      </div>
      <div className="canvas-widget__header-wrapper">
        {
          (isEditMode
            && ownCompany?.isModerated
            && (flowById?.status === 4 || flowById?.status === 2)
          ) && (
            <buttons.BasicButton
              filterType="white"
              text={flowById?.status === 4
                ? t('CONTROLS.BROADCASTS.CANCEL_MODERATION')
                : t('CONTROLS.BROADCASTS.SEND_TO_MODERATION')}
              icon={flowById?.status === 4
                ? finishIcon
                : preModerationIcon}
              type={flowById?.status === 4
                ? BasicButton.types.SECONDARY
                : BasicButton.types.ACCENT}
              onClick={onModerateProcess}
              loading={isPendingGetFlowToggleModerate || isPendingGetFlowById}
            />
          )
        }
        {
          !readOnlyAlways && (
            <div className="canvas-widget__switch-wrapper">
              <SwitchField
                label={isEditMode ? t('SCENARIOS_CONSTRUCTOR.INSTANCES.EDIT_MODE') : t('SCENARIOS_CONSTRUCTOR.INSTANCES.EDITION_DISABLED')}
                value={isEditMode}
                onChange={setIsEditMode}
              />
            </div>
          )
        }
      </div>
      {
        isEditMode && (
          <div
            className="canvas-widget__elements"
            data-tip={t('SCENARIOS_CONSTRUCTOR.DRAG_TO_CANVAS')}
            data-for="canvas-elements"
          >
            <div
              className="canvas-widget__elements__action"
              draggable
              onDragStart={event => event.dataTransfer.setData('storm-diagram-node', JSON.stringify({ type: NODE_TYPE.ACTION }))}
            >
              <img
                className="canvas-widget__elements__action__icon"
                alt=""
                src={lightningIcon}
              />
              {t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_ACTION')}
            </div>
            <div
              className="canvas-widget__elements__condition"
              draggable
              onDragStart={event => event.dataTransfer.setData('storm-diagram-node', JSON.stringify({ type: NODE_TYPE.CONDITION }))}
            >
              <img
                className="canvas-widget__elements__condition__icon"
                alt=""
                src={arrowsTwoIcon}
              />
              {t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_CONDITION_LOWERCASE')}
            </div>
            <Tooltip id="canvas-elements" place="left" />
          </div>
        )
      }
    </div>
  );
});
